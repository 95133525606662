* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.root-container {
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
}

.center-children {
  display: flex;
  justify-content: center;
}
