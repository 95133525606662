.restaurant-map p,
.jumbotron p {
  margin: 0;
  padding: 0;
}

.restaurant-map {
  position: relative;
  width: 100%;
  height: 70vh;
  margin-bottom: 1em;
}

.restaurant-map.loading::after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.restaurant-map.loading::before {
  content: '';
  border: 16px solid #f3f3f3;
  border-top: 16px solid #81a1c1;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;
  top: 50%;
  left: 50%;
  z-index: 100;
  position: absolute;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.no-interaction {
  user-select: none;
  pointer-events: none;
}

.restaurant-control {
  top: 2.5em;
  right: 0.5em;
}

.restaurant-map.clickable {
  cursor: pointer;
}
