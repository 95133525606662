.search-container {
  width: 100%;
  padding-top: 16px;
}

.restaurant-detail-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  width: 600px;
  max-height: 80%;
  overflow: scroll !important;
  display: block;
}

.restaurant-card {
  cursor: pointer;
}

.restaurant-card-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
