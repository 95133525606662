.table-selection-container {
  width: 100%;
  padding-top: 16px;
}

.floorPlan-canvas {
  position: relative;
  top: 0;
  left: 0;
}

.floorplan-table-image {
  transition: all 0.3s ease-in-out;
}

.floorplan-table-image:hover {
  filter: drop-shadow(0 0 0.3rem #2e3440);
}
