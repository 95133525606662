.hero-container {
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  margin: auto 0;
}

.hero-content {
  padding: 50px 0;
}

.hero-heading {
  font-size: 64px;
  line-height: 1.15;
}

.hero-subheading {
  margin: 20px 0 50px 0;
}

.hero-image-container {
  padding: 50px 0;
}

.background-image {
  width: 55%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 110, 255, 0.011),
      rgba(0, 183, 255, 0.011)
    ),
    url('../../public/images/background_3.jpg');
  background-position: 50% 70%;
}

.social-icons {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 20px;
  top: 50vh;
  transform: translateY(-50%);
  gap: 20px;
}

.social-icon {
  color: #5e81ac;
}

.social-icon:hover {
  color: #81a1c1;
}

.label {
  color: white;
}

.MuiCalendarPicker-root {
  width: 500px !important;
  margin: 10px 0 40px 0 !important;
  max-height: 100% !important;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.15) 0 60px 105px -22px,
    rgba(0, 0, 0, 0.15) 0 50px 55px -33px;
  background-color: white;
}

.PrivatePickersSlideTransition-root {
  min-height: 250px !important;
}

.MuiMonthPicker-root {
  min-height: 290px;
}

.MuiCalendarPicker-viewTransitionContainer > div > div:first-child {
  justify-content: space-around;
}

.PrivatePickersSlideTransition-root > div > div {
  justify-content: space-around;
}

.time-picker-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 500px;
}

.time-picker-container > div {
  background-color: white;
  border-radius: 5px;
}

.MuiToggleButtonGroup-root {
  width: 500px;
  margin: 10px 0 30px 0;
  background-color: white;
}

.MuiToggleButton-root {
  flex-grow: 1;
}

.imprint-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  width: 80%;
  max-height: 80%;
  overflow: scroll !important;
  display: block;
}
